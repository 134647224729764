import '../styles/globals.css';
import '../styles/landing.css'
import 'bootstrap-utilities/bootstrap-utilities.css';
import 'bootstrap/dist/css/bootstrap.css'
import '../styles/scss/global.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {useRouter} from "next/router";
import {handleLogout} from "components/utils";
import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {Nav, Navbar} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {Analytics} from '@vercel/analytics/react';

import {
    CLIENT,
    hasPermissions,
    hasPermissionsForBillingInventoryPage,
    hasPermissionsForManagePage,
    IPD,
    LAB,
    OPD,
    OPHTHALMOLOGY,
    RADIOLOGY
} from "../components/permissions";
import Marquee from "react-fast-marquee";

function MyApp({Component, pageProps}) {

    const router = useRouter();
    const [cookies, setCookie] = useCookies(['qme', 'token', 'qme_customer']);
    const [home, setHome] = useState(false);
    const [wallboard, setWallBoard] = useState(false);
    const [dl, setDl] = useState(false);
    const [payment, setPayment] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const cookieEntityQr = cookies.qme ? cookies.qme["entity_qr"] : null


    const roleNameMap = new Map<String, String>()
    roleNameMap.set("entity_admin", "Hospital Administrator")
    roleNameMap.set("fd_exec", "Front desk executive")
    roleNameMap.set("opd_manager", "OPD Manager")
    roleNameMap.set("ipd_executive", "IPD Executive")
    roleNameMap.set("ipd_manager", "IPD Manager")
    roleNameMap.set("nursing_staff", "Nursing Staff")
    roleNameMap.set("inv_manager", "Pharmacy Manager")
    roleNameMap.set("sales_exec", "Pharmacy Billing Executive")
    roleNameMap.set("radiologist", "Radiologist")
    roleNameMap.set("ophthalmic_technician", "Ophthalmic Technician")
    roleNameMap.set("custom_role", "Custom Role")
    roleNameMap.set("lab_manager", "Lab Manager")

    useEffect(() => {

        if (cookies.qme) {
            setIsLoggedIn(true)
        }

        if (router.pathname == "/admin" && (!cookies.qme || !(cookies.qme["role"] == "admin"))) {
            console.log(router.query.qr)
            router.push("/login?for=admin")
        }

        if (router.pathname == "/client" && !hasPermissions(cookies.qme, CLIENT)) {
            router.push("/login")
        }

        if (router.pathname == "/eadmin" && !hasPermissionsForManagePage(cookies.qme)) {
            router.push("/login")
        }

        if (router.pathname == "/fdexec" && !hasPermissions(cookies.qme, OPD)) {
            router.push("/login")
        }

        if (router.pathname == "/ipd" && !hasPermissions(cookies.qme, IPD)) {
            router.push("/login")
        }

        if (router.pathname == "/inventory" && !hasPermissionsForBillingInventoryPage(cookies.qme)) {
            router.push("/login")
        }

        if (router.pathname == "/radiology" && !hasPermissions(cookies.qme, RADIOLOGY)) {
            router.push("/login")
        }

        if (router.pathname == "/lab" && !hasPermissions(cookies.qme, LAB)) {
            router.push("/login")
        }

        if (router.pathname == "/ophthalmology" && !hasPermissions(cookies.qme, OPHTHALMOLOGY)) {
            router.push("/ophthalmology")
        }

        if (router.pathname == "/") {
            setHome(true)
        }

        if (router.pathname == "/wallboard") {
            setWallBoard(true)
        }

        if (router.pathname == "/l") {
            setDl(true)
        }
    }, [router.events]);

    useEffect(() => {
        if (router.pathname == "/payment") {
            setPayment(true)
        }
    }, [router.events, router.pathname]);

    function getNavBar() {
        return <div className="row">
            <div className="col col-12 col-lg-10 offset-lg-1">
                <Navbar bg="white" variant="light" expand="lg">
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Brand href="/">
                        <div className="d-flex">
                            <img
                                alt="qme-hmis"
                                src="/logo.svg"
                                className="align-self-center nav-logo"
                            />{' '}
                            <div className="nav-header align-self-center text-info d-none d-lg-block">&nbsp;QMe</div>
                        </div>
                    </Navbar.Brand>

                    <Navbar.Collapse aria-controls="basic-navbar-nav">
                        <Nav className="me-auto nav-menu">
                            <Nav.Link href="/">
                                <div className={router.pathname == "/" ? "active" : ""}>Home
                                </div>
                            </Nav.Link>
                            {(!cookies.qme) &&
                                <Nav.Link href="/login">
                                    <div className={router.pathname == "/login" ? "active" : ""}>Partner
                                        Login
                                    </div>
                                </Nav.Link>
                            }
                            {(!cookies.qme) &&
                                <Nav.Link href="/blogs">
                                    <div className={router.pathname == "/blogs" ? "active" : ""}>Blogs
                                    </div>
                                </Nav.Link>
                            }
                            {hasPermissionsForManagePage(cookies.qme) &&
                                <Nav.Link href="/eadmin">
                                    <div className={router.pathname == "/eadmin" ? "active" : ""}>Administrator</div>
                                </Nav.Link>
                            }
                            {hasPermissions(cookies.qme, CLIENT) &&
                                <Nav.Link href="/client">
                                    <div className={router.pathname == "/client" ? "active" : ""}>Sessions</div>
                                </Nav.Link>
                            }
                            {hasPermissions(cookies.qme, CLIENT) &&
                                <Nav.Link href="/client-opd">
                                    <div className={router.pathname == "/client-opd" ? "active" : ""}>Reports</div>
                                </Nav.Link>
                            }
                            {hasPermissions(cookies.qme, CLIENT) &&
                                <Nav.Link href="/client-settings">
                                    <div className={router.pathname == "/client-settings" ? "active" : ""}>Settings
                                    </div>
                                </Nav.Link>
                            }
                            {hasPermissions(cookies.qme, OPD) && localStorage.getItem("opd") == "true" &&
                                <Nav.Link href="/opd">
                                    <div className={router.pathname == "/opd" ? "active" : ""}>OPD</div>
                                </Nav.Link>
                            }
                            {hasPermissions(cookies.qme, IPD) && localStorage.getItem("ipd") == "true" &&
                                <Nav.Link href="/ipd">
                                    <div className={router.pathname == "/ipd" ? "active" : ""}>IPD</div>
                                </Nav.Link>
                            }
                            {hasPermissionsForBillingInventoryPage(cookies.qme) && localStorage.getItem("inventory") == "true" &&
                                <Nav.Link href="/inventory">
                                    <div className={router.pathname == "/inventory" ? "active" : ""}>Pharmacy
                                    </div>
                                </Nav.Link>
                            }
                            {hasPermissions(cookies.qme, RADIOLOGY) && localStorage.getItem("radiology") == "true" &&
                                <Nav.Link href="/radiology"
                                          className={router.pathname == "/radiology" ? "active" : ""}>
                                    <div className={router.pathname == "/radiology" ? "active" : ""}>Radiology</div>
                                </Nav.Link>
                            }
                            {hasPermissions(cookies.qme, LAB) && localStorage.getItem("lab") == "true" &&
                                <Nav.Link href="/lab"
                                          className={router.pathname == "/lab" ? "active" : ""}>
                                    <div className={router.pathname == "/lab" ? "active" : ""}>Lab</div>
                                </Nav.Link>
                            }
                            {!hasPermissionsForManagePage(cookies.qme) && hasPermissions(cookies.qme, OPHTHALMOLOGY) && (localStorage.getItem("opd") == "true" || localStorage.getItem("ipd") == "true") &&
                                <Nav.Link href="/ophthalmology"
                                          className={router.pathname == "/ophthalmology" ? "active" : ""}>
                                    <div className={router.pathname == "/ophthalmology" ? "active" : ""}>Ophthalmology
                                    </div>
                                </Nav.Link>
                            }
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar className="justify-content-end">
                        {isLoggedIn &&
                            <div className="row col-12">
                                <div className="col col-10 text-bg-primary align-content-center rounded rounded-5">
                                    <div className="fs-5 text-center">{cookies.qme["name"]}</div>
                                    {roleNameMap && roleNameMap.get(cookies.qme["role"]) && <div
                                        className="fs-8 text-center">{" [" + roleNameMap.get(cookies.qme["role"]) + "]"}</div>}
                                </div>
                                <div className="fs-8 col-2">
                                    <Nav.Link href="/login" onClick={handleLogout}
                                              className="font-weight-bold justify-content-end landing">
                                        <button className="btn btn-primary p-lg-3 p-2 action text-white">Logout</button>
                                    </Nav.Link>
                                </div>
                            </div>
                        }
                        {!isLoggedIn && <>

                            <Nav.Link href="/signup"
                                      className="font-weight-bold justify-content-end landing">
                                <button className="btn btn-primary p-lg-3 p-2 action text-white">Free Sign Up
                                    !
                                </button>
                            </Nav.Link>
                        </>
                        }
                    </Navbar>
                </Navbar>
            </div>
        </div>;
    }

    return (
        <div className="container-fluid">
            {!wallboard && !dl && !payment &&
                <>
                    {getNavBar()}
                    {/*{(cookieEntityQr == "qme-20" || cookieEntityQr == null) &&*/}
                    {/*    <div className="bg-danger text-white fw-bold">*/}
                    {/*        <Marquee gradient={false}>*/}
                    {/*            Your Annual Maintenance Cost (AMC) amount of INR 10000 is due. Please pay it not later*/}
                    {/*            than 15-10-2024 for uninterrupted service. Please reach out to 9971513514 or*/}
                    {/*            support@qme.co.in in case of any queries.*/}
                    {/*        </Marquee>*/}
                    {/*    </div>}*/}
                    <div className="row pb-5">
                        <div className="col-12 bg-lightGrey">
                            <Component {...pageProps} />
                            <Analytics/>
                        </div>
                    </div>
                </>
            }
            {(wallboard || dl || payment) &&
                <div className="row">
                    <Component {...pageProps} />
                    <Analytics/>
                </div>
            }
        </div>
    )
}

export default MyApp
